
import AppForm from "@/utils/form/components/Form.vue";
import data from "@/forms/auth";
// import {snackBar} from '@/utils/Helpers'
export default {
  data() {
    return {
      data,
    };
  },
  components: {
    "app-form": AppForm,
  },
};
