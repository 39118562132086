
import Vue from 'vue'
import {mapGetters} from 'vuex'
export default Vue.extend({
    computed:{
        ...mapGetters({
            snack :'ui/snackBar',
        })
    },
    watch:{
        snack :{
            handler : function (val) {
                if(val.Active == true) {
                    setTimeout(() => this.$store.commit('ui/stopSnack'), 5000)
                }
            },
            deep:true
        }
    }
})
