
import Form from '../Form'
import Vue from 'vue'
export default Vue.extend({
    props:{
        form:Form
    },
    watch:{
        "form.state": {
            handler() {
                // this.form.valid = false
                // let form =  this.form.state
                // form[input.name as keyof typeof form] = input.value
                    this.form.valid = this.form.validate()
                // if(this.form.validate()){
                //     // this.$emit('change' , input.value)
                // }
            },
            deep: true,
        },
    }
})
