
import Vue from "vue";
import Drawer from "@/components/layouts/Drawer.vue";
import { Validate } from "@/repositories/user";

export default Vue.extend({
  name: "App",
  components: {
    Drawer,
  },
  created(){
    if(!localStorage.getItem('token')) {
      this.$router.push('/login')
      return 
    }
     Validate().catch(() => {
        this.$router.push('/login')
      })
  }
});
